import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { css } from '@emotion/react'
import html from 'html-react-parser'

import { useTranslation } from 'next-i18next'

import { useMarket } from '@/utils/multi-markets/context'

import { ParkingsBySlugResponse } from '@/service'
import Modal from '@/components/common/Modal'
import api from '@/service'
import { first } from '@/utils/common'

import { H3Styles, Text2Styles } from '@/styles/typography'
import { weights, colors } from '@/styles/theme'
import { mediaUp } from '@/styles/media'
import BookParkingButtons from '@/components/parkings/BookParkingButtons'

import LoaderComponent from '../common/Loader'

function ParkingPricingModal() {
  const { t } = useTranslation(['common', 'parkings'])
  const router = useRouter()
  const { locale } = useMarket()
  const isActive = !!router.query.pricing
  const slug = first(router.query.pricing)
  const [parking, setParking] = useState<ParkingsBySlugResponse | null>()
  const [prices, setPrices] = useState<
    | {
        id: string
        name: string
        value: string
        locizeNameIdentifier: string
      }[]
    | null
  >()

  useEffect(() => {
    if (slug) {
      const getData = async () => {
        const [parking, plans] = await Promise.all([
          api.parkingsInternal.bySlug(slug, { lang: locale }).catch(() => {}),
          api.parkingsInternal.plan(slug, { lang: locale }).catch(() => {})
        ])
        setParking(parking?.data)

        if (plans && plans.data.pricePerHour) {
          setPrices([
            {
              id: '0',
              name: t('common:parkingHour'),
              value: `${plans.data.pricePerHour.amount} ${plans.data.pricePerHour.currency}`,
              locizeNameIdentifier: ''
            },
            ...plans.data.plans.map(plan => ({
              id: plan.uuid,
              name: plan.name,
              value: plan.price,
              locizeNameIdentifier:
                (t(`parkings:${plan.locizeNameIdentifier}` as any) as string) ??
                plan.name
            }))
          ])
        }
      }

      getData()
    } else {
      setParking(null)
      setPrices([])
    }
  }, [slug])

  function hide() {
    const query = router.query
    delete query['pricing']
    router.push({ query }, undefined, {
      shallow: true
    })
  }

  return (
    <Modal show={isActive} onClose={hide}>
      {parking && prices ? (
        <>
          <h2 css={style.title}>{parking.details.longDisplayName}</h2>
          <div css={style.list}>
            {prices.map(price => (
              <div key={price.id} css={style.entry}>
                <div css={style.subtitle}>
                  {html(
                    price.name === prices![0].name
                      ? price.name
                      : price.locizeNameIdentifier
                  )}
                </div>
                <div css={style.value}>{price.value}</div>
              </div>
            ))}
          </div>
          <div css={style.buttons}>
            <BookParkingButtons parking={parking.parking} />
          </div>
        </>
      ) : (
        <div css={style.loader}>
          <LoaderComponent color={'twilightBlue'} size={'large'} />
        </div>
      )}
    </Modal>
  )
}

const style = {
  title: css`
    ${H3Styles};
    margin-bottom: 18px;

    ${mediaUp('lg')} {
      margin-bottom: 30px;
    }
  `,
  list: css`
    display: flex;
    flex-direction: column;
  `,
  entry: css`
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 8px;

      ${mediaUp('lg')} {
        margin-bottom: 16px;
      }
    }
  `,
  subtitle: css`
    ${Text2Styles};
  `,
  value: css`
    ${Text2Styles};
    font-weight: ${weights.black};
    color: ${colors.twilightBlue};
  `,
  buttons: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 40px;

    ${mediaUp('lg')} {
      margin: 60px auto 0;
    }
  `,
  loader: css`
    position: relative;
    height: 32px;
    margin: 18px 0;

    ${mediaUp('lg')} {
      height: 60px;
      margin: 20px 0;
    }
  `
}

export default ParkingPricingModal
